import { SpaceBetween, FormField } from '@amzn/awsui-components-react';
import React from 'react';
import { useWatch, useFormContext, FieldValues } from 'react-hook-form';
import { ControlledAttributeEditor, ControlledInput, ControlledTextarea } from '@amzn/react-hook-form-polaris';

interface BodyElementProps {
  field: string;
}

function BodyElementAttributes({ field }: BodyElementProps): JSX.Element {
  const { control } = useFormContext();

  const type = useWatch({
    control,
    name: `${field}.type`,
    defaultValue: undefined,
  });

  const fieldsDefinition = [
    {
      label: 'Title',
      control: (fieldValues: FieldValues, index: number) => (
        <ControlledInput key={`${field}.${index}.title`} control={control} name={`${field}.fields.${index}.title`} />
      ),
    },
    {
      label: 'Value',
      control: (fieldValues: FieldValues, index: number) => (
        <ControlledInput key={field} control={control} name={`${field}.fields.${index}.value`} />
      ),
    },
  ];

  return ({
    text: (
      <FormField label="Text">
        <ControlledTextarea
          name={`${field}.text`}
          control={control} />
      </FormField>
    ),
    image: (<SpaceBetween direction="vertical" size="l">
      <FormField label="Image Url">
        <ControlledInput
          name={`${field}.imageLocation`}
          control={control} />
      </FormField>
      <FormField label="Fallback Text">
        <ControlledInput
          name={`${field}.fallbackText`}
          control={control} />
      </FormField>

      <FormField label="Caption">
        <ControlledInput
          name={`${field}.imageCaption`}
          control={control} />
      </FormField>
    </SpaceBetween>
    ),
    fields: (
      <ControlledAttributeEditor
        name={`${field}.fields`}
        control={control}
        definition={fieldsDefinition}
        defaultValue={{ title: 'title', value: 'value' }}
        addButtonText="Add"
        removeButtonText="Remove"
        empty="No items associated with the resource." />
    ),
  })[type as string] ?? (<></>);
}

export default BodyElementAttributes;
