import { SpaceBetween, FormField, Button, ExpandableSection } from '@amzn/awsui-components-react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ControlledSelect } from '@amzn/react-hook-form-polaris';
import BodyElementAttributes from './BodyElementAttributes';
import ActionsGroupElement from './ActionsGroupElement';

function BodyElementsBMMv2(): JSX.Element {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'body',
  });

  // TODO why does <> introduce level of nesting preventing use of parent space between?
  return <SpaceBetween direction="vertical" size="l"> 
    {fields.map((field, index) => (
      <ExpandableSection
        key={index}
        variant="container"
        headerActions={<Button formAction="none" variant="normal" onClick={() => remove(index)}>
          Delete
        </Button>}
        // headerInfo={<Link variant="info">Info</Link>}
        headerText="Body Element"
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label="Type">
            <ControlledSelect
              name={`body.${index}.type`}
              control={control}
              options={[
                { label: 'Text', value: 'text' },
                { label: 'Image', value: 'image' },
                { label: 'Fields', value: 'fields' },
                { label: 'Actions', value: 'default' },
                { label: 'Context', value: 'context' },
                { label: 'Timestamp', value: 'timestamp' },
                { label: 'Divider', value: 'divider' },
              ]} />
          </FormField>
          <BodyElementAttributes field={`body.${index}`} />
          <ExpandableSection headerText="Actions">
            <ActionsGroupElement name={`body.${index}.actions`}></ActionsGroupElement>
          </ExpandableSection>
        </SpaceBetween>
      </ExpandableSection>
    ))}

    <Button variant="primary" onClick={() => append({ type: 'text', text: '' })}>Add Element</Button>
  </SpaceBetween>;
}

export default BodyElementsBMMv2;
