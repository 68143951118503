// TODO Update copy

export const codeEditorStrings = {
  loadingState: 'Loading code editor',
  errorState: 'There was an error loading the code editor.',
  errorStateRecovery: 'Retry',
  editorGroupAriaLabel: 'Code editor',
  statusBarGroupAriaLabel: 'Status bar',
  cursorPosition: (row: number, column: number): string => `Ln ${row}, Col ${column}`,
  errorsTab: 'Errors',
  warningsTab: 'Warnings',
  preferencesButtonAriaLabel: 'Preferences',
  paneCloseButtonAriaLabel: 'Close',
  preferencesModalHeader: 'Preferences',
  preferencesModalCancel: 'Cancel',
  preferencesModalConfirm: 'Confirm',
  preferencesModalWrapLines: 'Wrap lines',
  preferencesModalTheme: 'Theme',
  preferencesModalLightThemes: 'Light themes',
  preferencesModalDarkThemes: 'Dark themes',
};

export const tagEditorStrings = {
  keyPlaceholder: 'Enter key',
  valuePlaceholder: 'Enter value',
  addButton: 'Add new variable',
  removeButton: 'Remove',
  undoButton: 'Undo',
  undoPrompt: 'This tag will be removed upon saving changes',
  loading: 'Loading tags that are associated with this resource',
  keyHeader: 'Key',
  valueHeader: 'Value',
  optional: 'required',
  keySuggestion: 'Custom tag key',
  valueSuggestion: 'Custom tag value',
  emptyTags: 'No tags associated with the resource.',
  tooManyKeysSuggestion: 'You have more keys than can be displayed',
  tooManyValuesSuggestion: 'You have more values than can be displayed',
  keysSuggestionLoading: 'Loading tag keys',
  keysSuggestionError: 'Tag keys could not be retrieved',
  valuesSuggestionLoading: 'Loading tag values',
  valuesSuggestionError: 'Tag values could not be retrieved',
  emptyKeyError: 'You must specify a tag key',
  maxKeyCharLengthError: 'The maximum number of characters you can use in a tag key is 128.',
  maxValueCharLengthError: 'The maximum number of characters you can use in a tag value is 256.',
  duplicateKeyError: 'You must specify a unique tag key.',
  invalidKeyError: 'Invalid key. Keys can only contain Unicode letters, digits, white space and any of the following: _.:/=+@-',
  invalidValueError: 'Invalid value. Values can only contain Unicode letters, digits, white space and any of the following: _.:/=+@-',
  awsPrefixError: 'Cannot start with aws:',
  tagLimit: (availableTags: number): string => availableTags === 1
    ? 'You can add up to 1 more tag.'
    : 'You can add up to ' + availableTags + ' more tags.',
  tagLimitReached: (tagLimit: number): string => tagLimit === 1
    ? 'You have reached the limit of 1 tag.'
    : 'You have reached the limit of ' + tagLimit + ' tags.',
  tagLimitExceeded: (tagLimit: number): string => tagLimit === 1
    ? 'You have exceeded the limit of 1 tag.'
    : 'You have exceeded the limit of ' + tagLimit + ' tags.',
  enteredKeyLabel: (key: string): string => 'Use "' + key + '"',
  enteredValueLabel: (value: string): string => 'Use "' + value + '"',
};
