/* eslint-disable camelcase */

import JWT from 'jwt-decode';
import * as qs from 'querystring';

import { WebIdentityCredentials } from 'aws-sdk';

// TODO cache
// TODO remove id_token query param on page after midway redirect https://stackoverflow.com/a/74855544
export async function fetchSettings() {
  try {
    const response = await fetch('/config.json');
    return await response.json();
  } catch (e) {
    console.error('Unable to fetch/set settings from /settings.json', e);
  }
}

// https://docs.aws.amazon.com/sdk-for-javascript/v2/developer-guide/loading-browser-credentials-federated-id.html

/** Used to ensure every request is unique */
function generateNonce() {
  return Math.random().toString(36)
    .substring(2, 15) + Math.random().toString(36)
    .substring(2, 15);
}

/**
 * Builds Midway request url that we query to get the WebIdentityToken
 * (through passing the cookie that was stored in our browser from the CloudFront Viewer-Request Lambda@Edge)
 *
 */
function buildMidwayRequestUrl(shouldRedirect = false) {
  const params = {
    scope: 'openid',
    response_type: 'id_token',
    client_id: window.location.origin,
    redirect_uri: window.location.origin,
    nonce: generateNonce(),
  };
    /*
     *   if (!shouldRedirect) {
     *     params.sentry_handler_version = "MidwayNginxModule-1.3-1";
     *   }
     */
  const midwayUrl = 'https://midway-auth.amazon.com/SSO';
  const baseUrl = shouldRedirect ? `${midwayUrl}/redirect?` : `${midwayUrl}?`;
  return baseUrl + qs.stringify(params);
}

/** Tries to get the token from Midway without redirection */
async function fetchToken() {
  const midwayRequestUrl = buildMidwayRequestUrl();
  const response = await fetch(midwayRequestUrl, { credentials: 'include' });
  if(response.status === 200) {
    return await response.text(); // formerly "data"
  }
  throw JSON.parse(await response.text());

}

/**
 * Tries to fetch updated token without redirecting, but redirects if it has to
 */
async function getWebIdentityTokenOrRedirect() {
  if(window.location.hostname.indexOf('amazon.com') == -1 && window.location.hostname.indexOf('aws.dev') == -1) {
    throw new Error('Access through localhost is not possible due to CORS - see README for more details');
  }
  try {
    console.log('Renewing Web Identity token ...');
    return await fetchToken();
  } catch (e: any) {
    if(e.message === 'Unauthenticated' || e.message === 'Unauthorized') { // I have not seen Unauthorized but the sample code had it
      console.log('Redirecting to Midway because session has expired');
      const shouldRedirect = true;
      window.location.href = buildMidwayRequestUrl(shouldRedirect);
    } else {
      console.error('Error when getting Web Identity token.');
      throw e;
    }
  }
}
/**
 * Decodes JavaScript Web Token and returns the username
 */
function decodeUsername(token: string) {
  const decoded = JWT<any>(token);
  const userAlias = decoded.sub;
  return userAlias;
}

/**
 * Fetch settings, WebIdentityToken, decode token to get username,
 * pass token to get temporary AWS credentials for the role defined in CDK, return settings and username
 */
export async function init() {
  const [webIdentityToken, settings] = await Promise.all([getWebIdentityTokenOrRedirect(), fetchSettings()] as const);
  const username = decodeUsername(webIdentityToken!);

  const creds = new WebIdentityCredentials({
    RoleArn: settings.role, // settings.OpenIdAssumedRoleArn,
    RoleSessionName: username,
    WebIdentityToken: webIdentityToken!, // Access token from identity provider
  }, {
    region: 'us-west-2',
  });

  return {
    creds,
    webIdentityToken,
    username,
  };
}
