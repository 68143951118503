import { WheatleyMessagePreviewerClient } from './clients';

export async function getSample(name: string) {
  try {
    // TODO set dirty bit so we warn user if they have updated
    const client = await WheatleyMessagePreviewerClient();
    const response = await client.getSample({ name }).promise();
    return JSON.stringify(JSON.parse(response.content), null, 2);
  } catch (e) {
    console.error(`Unable to fetch sample ${name}!`, e);
  }
}
