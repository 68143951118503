require('aws-sdk/lib/node_loader');
var AWS = require('aws-sdk/lib/core');
var Service = AWS.Service;
var apiLoader = AWS.apiLoader;

apiLoader.services['wheatleymessagepreviewer'] = {};
AWS.WheatleyMessagePreviewer = Service.defineService('wheatleymessagepreviewer', ['2018-05-10']);
Object.defineProperty(apiLoader.services['wheatleymessagepreviewer'], '2018-05-10', {
  get: function get() {
    var model = require('../apis/wheatleymessagepreviewer-2018-05-10.min.json');
    model.paginators = require('../apis/wheatleymessagepreviewer-2018-05-10.paginators.json').pagination;
    return model;
  },
  enumerable: true,
  configurable: true
});

module.exports = AWS.WheatleyMessagePreviewer;
