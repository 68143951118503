import { Select, SelectProps } from '@amzn/awsui-components-react';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import React, { useContext, useEffect } from 'react';

import { SET_BOT_MARKUP, SET_MARKUP_VALIDATION, SET_SAMPLES } from '../../state/actions';
import { AppContext } from '../../state/context/app-context';
import { WheatleyMessagePreviewerClient } from '../../util/clients';
import { getSample } from '../../util/samples';

interface SampleSelectorProps {
  disabled: boolean,
}

function SampleSelector({ disabled }: SampleSelectorProps): JSX.Element {
  const { dispatch, state } = useContext(AppContext);

  const getOptions = async () => (await (await WheatleyMessagePreviewerClient()).listSamples({ schema: 'bot-markup' }).promise()).samples;

  useEffect(() => {
    getOptions().then(sampleNames => {
      dispatch({
        payload: sampleNames,
        type: SET_SAMPLES,
      });
    });
  }, []);

  const [
    selectedOption,
    setSelectedOption,
  ] = React.useState(null as SelectProps.Option | null);

  const onChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = async ({ detail }) => {
    if(state.editorDirty) {
      if(!confirm('Would you like to overwrite your current content?')) {
        return;
      }
    }
    setSelectedOption(detail.selectedOption);
    dispatch({
      type: SET_BOT_MARKUP,
      payload: {
        content: (await getSample(detail.selectedOption.value!))!,
        source: 'sample',
      },
    });
    dispatch({
      type: SET_MARKUP_VALIDATION,
      payload: {},
    });
  };

  return (
    <Select
      selectedOption={selectedOption}
      onChange={onChange}
      options={state.sampleNames.map(fileName => ({
        label: fileName.slice(fileName.lastIndexOf('/') + 1),
        value: fileName,
        description: fileName.slice(0, fileName.lastIndexOf('/')),
      }))}
      filteringType="auto"
      statusType={state.sampleNames.length ? 'finished' : 'loading'}
      loadingText="Loading samples"
      placeholder="Choose a sample to load"
      disabled={disabled}
    />
  );
}

export default SampleSelector;
