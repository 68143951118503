import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { fromBase64 } from '../../util/base64';
import { getSample } from '../../util/samples';

import {
  AppAction,
  CLEAR_NOTIFICATIONS,
  SET_BOT_MARKUP,
  SET_MARKUP_VALIDATION,
  SET_NOTIFICATIONS,
  SET_PAYLOAD_SCHEMA,
  SET_PLATFORM_MARKUP,
  SET_PLATFORM_TAB,
  // SET_TAGS,
  SET_SAMPLES,
} from '../actions';

export interface AppState {
    botMarkup: string;
    inputChanged: boolean;
    isValidMarkup: boolean;
    notifications: Array<FlashbarProps.MessageDefinition>;
    platformMarkup: Record<string, string>;
    selectedTab: string;
    // tags: TagEditorProps.Tag[]
    sampleNames: string[];
    editorDirty: boolean;
    payloadSchema: 'bot-markup' | 'custom-notification';
}

let initialContent = undefined;
try {
  const hash = new URL(window.location.href).hash;
  if(hash.length > 1) {
    initialContent = hash.slice(1);
    initialContent = JSON.stringify(JSON.parse(
      fromBase64(hash.slice(1)),
    ), undefined, 4);
  }
} catch (e) {
  console.log('Failed to handle hash', e);
}

initialContent = initialContent ?? (await getSample('BIS/runbook-document-name-dropdown.json'))!;

export const appInitialState: AppState = {
  botMarkup: initialContent,
  inputChanged: true,
  isValidMarkup: true,
  platformMarkup: {},
  selectedTab: 'microsoft-teams',
  // tags: basicMessageTags,
  notifications: [],
  sampleNames: [],
  editorDirty: false,
  payloadSchema: 'bot-markup',
};

export function appReducer(state: AppState, action: AppAction): AppState {
  // console.log(action);
  switch (action.type) {
  case SET_BOT_MARKUP: {
    return {
      ...state,
      botMarkup: action.payload.content,
      inputChanged: true,
      isValidMarkup: false,

      /*
       * setting isValidMarkup to false when we update the markdown
       * because a subsequent call will dispatch an action to set this to true if it's valid
       */

      // TODO can we prevent the editor from firing when a sample is selected?
      editorDirty: action.payload.content !== state.botMarkup && action.payload.source === 'user',
      platformMarkup: {},
    };
    /*
     * case SET_TAGS: {
     *     return {
     *         ...state,
     *         inputChanged: true,
     *         tags: action.payload,
     *     };
     * }
     */
  }
  case SET_PLATFORM_TAB: {
    return {
      ...state,
      inputChanged: true,
      selectedTab: action.payload,
    };
  }
  case SET_MARKUP_VALIDATION: {
    return {
      ...state,
      inputChanged: true,
      isValidMarkup: action.payload,
    };
  }
  case SET_PLATFORM_MARKUP: {
    return {
      ...state,
      inputChanged: false, // when the API returns the platform markup, this should not trigger a subsequent API call
      platformMarkup: {
        ...state.platformMarkup,
        [action.payload.client]: action.payload.markup,
      },
      notifications: [],
    };
  }
  case SET_NOTIFICATIONS: {
    return {
      ...state,
      inputChanged: false,
      notifications: action.payload,
    };
  }
  case CLEAR_NOTIFICATIONS: {
    return {
      ...state,
      inputChanged: false,
      notifications: [],
    };
  }
  case SET_SAMPLES: {
    return {
      ...state,
      sampleNames: action.payload,
    };
  }
  case SET_PAYLOAD_SCHEMA: {
    return {
      ...state,
      payloadSchema: action.payload,
    };
  }
  default:
    return state;
  }
}
