import { SpaceBetween, FormField } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { ControlledInput, ControlledSelect } from '@amzn/react-hook-form-polaris';

interface ActionsElementProps {
  name: string;
}

function ActionsElement({ name }: ActionsElementProps): JSX.Element {
  const { control } = useFormContext();

  const actionType = useWatch({
    control,
    name: `${name}.type`,
    defaultValue: undefined,
  }) as string;

  return (
    <SpaceBetween direction="vertical" size="l">
      <FormField label="Type">
        <ControlledSelect
          name={`${name}.type`}
          control={control}
          options={[
            { label: 'Button', value: 'button' },
            { label: 'Dropdown', value: 'dropdown' },
          ]} />
      </FormField>
      {{
        button: (
          <FormField label="Text">
            <ControlledInput
              name={`${name}.text`}
              control={control} />
          </FormField>
        ),
        dropdown: (<></>),
      }[actionType]}
      {/* <BodyElement field={`body.${index}`} /> */}
    </SpaceBetween>
  );
}

export default ActionsElement;
