import React, { useContext } from 'react';
import { AppLayout,
  Container,
  ColumnLayout,
  Flashbar } from '@amzn/awsui-components-react';
import BotMarkupInput from '../components/BotMarkupInput';
import BotMarkupPreviewer from '../components/BotMarkupPreviewer';
import { AppContext } from '../state/context/app-context';
import { useMidway } from '../state/hooks/midway';


const Content = () => {
  const { state } = useContext(AppContext);
  useMidway();
  return (
    <Container>
      <Flashbar items={state.notifications} />
      <h1>Aws Wheatley BMM Renderer</h1>
      <ColumnLayout columns={2}>
        <BotMarkupInput />
        <BotMarkupPreviewer />
      </ColumnLayout>
    </Container>
  );
/*
 *     return (
 *         <ContentLayout
 *             header={
 *                 <SpaceBetween size="m">
 *                     <Header
 *                         variant="h1"
 *                         info={<Link>Info</Link>}
 *                         description="This is a generic description used in the header."
 *                         actions={
 *                         <Button variant="primary">Button</Button>
 *                         }
 *                     >
 *                     BMM Message Previewer
 *                     </Header>
 *                 </SpaceBetween>
 *             }
 *         >
 *       <Container
 *         header={
 *           <Header
 *             variant="h2"
 *             description="Container description"
 *           >
 *             Container header
 *           </Header>
 *         }
 *       >
 *         Container content
 *       </Container>
 *     </ContentLayout>
 *     )
 */
};

const App = () => {
  return (
    <div>
      <AppLayout content={<Content />} disableContentPaddings={true} toolsHide={true} navigationHide={true} />
    </div>
  );
};

export default App;
