import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';

import { useEffect } from 'react';
import { init } from '../../util/midway';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface MidwayCredentialsHook {
}

export function useMidway(): MidwayCredentialsHook {
  useEffect(function() {
    init();
  }, []);

  return { };
}
