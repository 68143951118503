// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
export function fromBase64(base64: string) {
  const binString = atob(base64);
  // @ts-ignore
  const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));
  return new TextDecoder().decode(bytes);
}

export function toBase64(string: string) {
  const bytes = new TextEncoder().encode(string);
  const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte),
  ).join('');
  return btoa(binString);
}
