import { SpaceBetween, Form } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useWatch } from 'react-hook-form';

import { AppContext } from '../../state/context/app-context';
import { SET_BOT_MARKUP, SET_MARKUP_VALIDATION } from '../../state/actions';
import { bmmSchema } from '../../util/schemas';
import TitleAttributes from './TitleAttributes';
import BodyElementsBMMv2 from './BodyElementsBMMv2';
import BodyElementBMMv1 from './BodyElementBMMv1';

function BotMarkupForm(): JSX.Element {
  const { dispatch, state } = useContext(AppContext);

  const debounce = useMemo(() => {
    let listener: any = undefined;

    return function(func: (() => void), timeout = 25) {
      if(listener) {
        clearTimeout(listener);
      }

      listener = setTimeout(() => {
        func();
        listener = undefined;
      }, timeout);
    }
  }, []);

  const schema = bmmSchema;

  const context = useForm({
    values: JSON.parse(state.botMarkup),
    /*
     * defaultValues: {
     *   botMarkupMessageVersion: 1, // TODO how to fix numbers
     * },
     */
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { control, watch } = context;

  useEffect(() => {
    const subscription = watch(values => {
      const content = JSON.stringify(values, null, 2);
      if(state.botMarkup != content) {
        /*
          * values['botMarkupMessageVersion'] = Number(values['botMarkupMessageVersion']);
          * console.log('Changed', values);
          * console.log('Defaulted', schema.cast(values));
          */

        // TODO we need debounce to prevent the form triggering multiple times during initial render, but is there a better home?
        debounce(() => {
          dispatch({ type: SET_BOT_MARKUP, payload: { source: 'user', content } });
          dispatch({ type: SET_MARKUP_VALIDATION, payload: {} }); // TODO when do we actually call?
        });
        // if (formState.isValid) {
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, state.botMarkup]);

  const bmmVersion = useWatch({
    control,
    name: 'botMarkupMessageVersion',
  });

  return (
    <FormProvider {...context}>
      <form onSubmit={e => e.preventDefault()}>
        <Form>
          <SpaceBetween direction="vertical" size="l">
            <TitleAttributes />
            {
              bmmVersion === 1
                ? <BodyElementBMMv1 />
                : <BodyElementsBMMv2 />
            }
          </SpaceBetween>
        </Form>
      </form>
    </FormProvider>
  );
}

export default BotMarkupForm;
