import { FlashbarProps, Spinner } from '@amzn/awsui-components-react';
import Tabs, { TabsProps } from '@amzn/awsui-components-react/polaris/tabs';
import React, { lazy, Suspense, useCallback, useContext, useEffect, useMemo } from 'react';

import { CLEAR_NOTIFICATIONS, SET_NOTIFICATIONS, SET_PLATFORM_MARKUP, SET_PLATFORM_TAB } from '../../state/actions';
import { AppContext } from '../../state/context/app-context';
import { AppState } from '../../state/reducers';
import { WheatleyMessagePreviewerClient } from '../../util/clients';

const LazyChimePreview = lazy(() => import('./ChimePreview'));
const LazySlackPreview = lazy(() => import('./SlackPreview'));
const LazyTeamsPreview = lazy(() => import('./TeamsPreview'));

function BotMarkupPreviewer(): JSX.Element {
  const { dispatch, state } = useContext(AppContext);
  const onChangeHandler = useCallback(function(event) {
    dispatch({
      type: SET_PLATFORM_TAB,
      payload: event.detail.activeTabId,
    });
  }, [ dispatch ]);

  const { botMarkup, selectedTab, payloadSchema, inputChanged, isValidMarkup } = state;

  useEffect(function() {
    async function getPlatformMarkup() {
      if(inputChanged && isValidMarkup) {

        try {
          const platformMarkup = await (await WheatleyMessagePreviewerClient()).transform({
            integration: selectedTab,
            markupSchema: payloadSchema,
            payload: JSON.stringify(JSON.parse(botMarkup)), // remove extraneous whitespace
            // templateValues: {},
          }).promise();

          dispatch({
            payload: {
              client: selectedTab,
              markup: platformMarkup.result!,
            },
            type: SET_PLATFORM_MARKUP,
          });
        } catch (error) {
          const notifications: FlashbarProps.MessageDefinition[] = [
            {
              type: 'error',
              dismissible: true,
              dismissLabel: 'Dismiss message',
              onDismiss() {
                dispatch({
                  payload: notifications,
                  type: CLEAR_NOTIFICATIONS,
                });
              },
              content: error && typeof error === 'object' && 'message' in error ? error.message : undefined,
            },
          ];
          dispatch({
            payload: notifications,
            type: SET_NOTIFICATIONS,
          });
        }
      }
    }

    getPlatformMarkup();
  }, [ botMarkup, selectedTab, payloadSchema, inputChanged, isValidMarkup, dispatch ]);

  const platforms = useMemo<TabsProps.Tab[]>(function() {
    /*
     * TODO https://react.dev/reference/react/lazy
     * TODO https://react.dev/reference/react/Suspense#showing-stale-content-while-fresh-content-is-loading
     */
    const fallback = (<Spinner size="large" />);
    const loadingWrapper = (state: AppState, key: string, content: (content: string) => any) => state.platformMarkup[key] ? <Suspense fallback={fallback}>{ content(state.platformMarkup[key]) } </Suspense> : fallback;

    return [
      {
        name: 'Teams',
        id: 'microsoft-teams',
        label: 'Teams visualization',
        content: loadingWrapper(state, 'microsoft-teams', content => <LazyTeamsPreview content={content} />),
      },
      {
        name: 'Chime',
        id: 'chime',
        label: 'Chime visualization',
        content: loadingWrapper(state, 'chime', content => <LazyChimePreview content={content} />),
      },
      {
        name: 'Slack',
        id: 'slack',
        label: 'Slack visualization',
        content: loadingWrapper(state, 'slack', content => <LazySlackPreview content={content} />),
      },
    ];
  }, [ state ]);

  return (
    <Tabs
      activeTabId={state.selectedTab}
      onChange={onChangeHandler}
      tabs={platforms}
    />
  );
}

export default BotMarkupPreviewer;
