import { Header, SpaceBetween, FormField, Container } from '@amzn/awsui-components-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledInput, ControlledTiles } from '@amzn/react-hook-form-polaris';

function TitleAttributes() {
  const { control } = useFormContext();

  return <Container
    header={<Header variant="h2">
      Title
    </Header>}
  >
    <SpaceBetween direction="vertical" size="l">
      <FormField label="Title">
        <ControlledInput
          name="title.text"
          control={control} />
      </FormField>
      <FormField label="Title Url">
        <ControlledInput
          name="titleUrl"
          control={control} />
      </FormField>

      <FormField label="Bot Markup Version">
        <ControlledTiles
          name="botMarkupMessageVersion"
          control={control}
          items={[
            { value: 1 as any as string, label: 'V1' },
            { value: 2 as any as string, label: 'V2' },
          ]} />
      </FormField>
    </SpaceBetween>
  </Container>;
}

export default TitleAttributes;
