import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { toBase64 } from '../../util/base64';
import { AppAction } from '../actions';

import { appInitialState, appReducer, AppState } from '../reducers';

interface AppContextType {
    dispatch: React.Dispatch<AppAction>
    state: AppState,
}

export const AppContext = createContext({} as AppContextType);

interface AppContextProviderProps {
    children: React.ReactNode
}

export function AppContextProvider({ children }: AppContextProviderProps): JSX.Element {
  const [ state, dispatch ] = useReducer(appReducer, appInitialState);
  const contextValue = useMemo(function() {
    return {
      state,
      dispatch,
    };
  }, [ state, dispatch ]);


  useEffect(() => {
    try {
      if(state.editorDirty) {
        const content = JSON.stringify(JSON.parse(state.botMarkup));

        const url = new URL(window.location.href);
        url.hash = toBase64(content);
        window.history.replaceState({}, '', url);
      }
    } catch (e) {}
  }, [state.botMarkup, state.editorDirty])

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
}
