import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';

import { CodeEditor, CodeEditorProps, SegmentedControl, SpaceBetween, Tabs, TabsProps } from '@amzn/awsui-components-react';
import React, { useContext, useMemo, useState } from 'react';

import { codeEditorStrings } from '../../i18n/components';
import { SET_BOT_MARKUP, SET_MARKUP_VALIDATION, SET_PAYLOAD_SCHEMA } from '../../state/actions';
import { AppContext } from '../../state/context/app-context';
import { useAce } from '../../state/hooks/ace';
import SampleSelector from '../SampleSelector';
import BotMarkupForm from '../BotMarkupForm';

function BotMarkupInput(): JSX.Element {
  const [ preferences, setPreferences ] = useState<CodeEditorProps.Preferences | undefined>(undefined);
  const { dispatch, state } = useContext(AppContext);
  const { ace, isLoading } = useAce();

  const dirty = React.useRef(false);
  dirty.current = state.editorDirty;

  /*
   * useEffect(() => {
   *   window.addEventListener('beforeunload', e => {
   *     console.log(e)
   *     if(dirty.current) {
   *       e.preventDefault();
   *     }
   *   })
   * }, []);
   */

  const changeHandlers = useMemo(function() {
    return {
      onPreferencesChange(event) {
        setPreferences(event.detail);
      },
      onValidate(event) {
        const isValidMarkup = event.detail.annotations.length === 0;
        dispatch({
          payload: isValidMarkup,
          type: SET_MARKUP_VALIDATION,
        });
      },
      onDelayedChange(event) {
        console.log('Hello!');
        dispatch({
          type: SET_BOT_MARKUP,
          payload: {
            source: 'user',
            content: event.detail.value,
          },
        });
      },
    } satisfies Partial<CodeEditorProps>;
  }, [ dispatch ]);

  const [ height, setHeight ] = useState(window.innerHeight * .65);

  const tabs: TabsProps.Tab[] = [
    {
      id: 'editor',
      label: 'Code editor',
      content: (
        <CodeEditor
          ace={ace}
          i18nStrings={codeEditorStrings}
          language="json"
          loading={isLoading}
          onDelayedChange={changeHandlers.onDelayedChange}
          onPreferencesChange={changeHandlers.onPreferencesChange}
          onValidate={changeHandlers.onValidate}
          preferences={preferences}
          value={state.botMarkup}
          themes={{ light: [ 'dawn' ], dark: [ 'tomorrow_night_bright' ] }}
          editorContentHeight={height}
          onEditorContentResize={event => setHeight(event.detail.height)}
        />
      ),
    },
    {
      id: 'form',
      label: '🧪 Form builder',
      content: (
        <BotMarkupForm></BotMarkupForm>
      ),
    },
  ];

  return (
    <>
      <SpaceBetween size="s">
        <SegmentedControl
          selectedId={state.payloadSchema}
          onChange={({ detail }) => dispatch({
            type: SET_PAYLOAD_SCHEMA,
            payload: detail.selectedId as any,
          })
          }
          label="Schema of payload"
          options={[
            { text: 'Bot Markup', id: 'bot-markup' },
            { text: 'Custom Notification', id: 'custom-notification' },
          ] as const}
        />
        <SampleSelector disabled={state.payloadSchema !== 'bot-markup'}/>
        <Tabs
          tabs={tabs}
        />
      </SpaceBetween>
    </>
  );
}

export default BotMarkupInput;
