import { object, string, array, number } from 'yup';

export const bmmSchema = object().shape({
  title: object().shape({
    text: string()
    /*
     * .matches(/^[a-zA-Z0-9_-]+$/, 'Invalid Name')
     * .max(64)
     */
      .default('')
      .required(),
  }),
  titleUrl: string(), // .max(256),

  text: object().shape({
    text: string()
    /*
     * .matches(/^[a-zA-Z0-9_-]+$/, 'Invalid Name')
     * .max(64)
     */
      .required(),
  })
    .when('botMarkupMessageVersion', {
      is: 1,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().transform(() => null),
    }),

  fields: array().of(
    object().shape({
      title: string()
      /*
       * .matches(/^[a-zA-Z0-9_-]+$/, 'Invalid Name')
       * .max(64)
       */
        .required(),

      value: string()
      /*
       * .matches(/^[a-zA-Z0-9_-]+$/, 'Invalid Name')
       * .max(64)
       */
        .required(),
    }),
  )
    .when('botMarkupMessageVersion', {
      is: 1,
      otherwise: (schema) => schema.transform(() => undefined),
    })
    .nullable(),

  body: array().of(
    object().shape({
      type: string().required(),
      text: string()
      /*
       * .matches(/^[a-zA-Z0-9_-]+$/, 'Invalid Name')
       * .max(64)
       * .required()
       */
      ,
    })
      .default({ type: 'text', text: '' }),
  )
    .when('botMarkupMessageVersion', {
      is: 2,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.transform(() => undefined),
    }),

  botMarkupMessageVersion: number().default(1),
});
