import { Header, SpaceBetween, FormField, Container } from '@amzn/awsui-components-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledTextarea } from '@amzn/react-hook-form-polaris';

function BodyElementBMMv1(): JSX.Element {
  const { control } = useFormContext();

  return <Container
    header={<Header variant="h2">
      Content
    </Header>}
  >
    <SpaceBetween direction="vertical" size="l">
      <FormField label="Text">
        <ControlledTextarea
          name="text.text"
          control={control} />
      </FormField>
    </SpaceBetween>
  </Container>;
}

export default BodyElementBMMv1;
