export const SET_BOT_MARKUP = 'SET_BOT_MARKUP';
export const SET_TAGS = 'set_tags';
export const SET_PLATFORM_TAB = 'set_platform_tab';
export const SET_PLATFORM_MARKUP = 'set_platform_markup';
export const SET_MARKUP_VALIDATION = 'set_markup_validation';
export const CLEAR_NOTIFICATIONS = 'clear_notifications';
export const SET_NOTIFICATIONS = 'add_notifications';
export const SET_SAMPLES = 'set_samples';
export const SET_PAYLOAD_SCHEMA = 'set_payload_schema';

export type AppAction = {
    type: typeof SET_TAGS | typeof SET_PLATFORM_TAB | typeof SET_MARKUP_VALIDATION | typeof SET_NOTIFICATIONS | typeof SET_SAMPLES;
    payload: any;
} | {
    type: typeof SET_BOT_MARKUP
    payload: {
        content: string;
        source: 'user' | 'sample';
    }
} | {
    type: typeof SET_PAYLOAD_SCHEMA,
    payload: 'bot-markup' | 'custom-notification';
} | {
    type: typeof SET_PLATFORM_MARKUP,
    payload: {
        client: string,
        markup: string,
    }
} | {
    type: typeof CLEAR_NOTIFICATIONS,
};
