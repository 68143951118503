import { WheatleyMessagePreviewer } from '@amzn/wheatley-message-previewer-client';
import { fetchSettings, init } from './midway';

// TODO we can also late assign AWS.config.credentials.params.WebIdentityToken = accessToken;

let client: WheatleyMessagePreviewer | undefined = undefined;

const getConfiguration = async () => ({
  credentials: (await init()).creds,
  endpoint: (await fetchSettings()).endpoint,
  region: 'us-west-2',
});

export const WheatleyMessagePreviewerClient = async () => {
  if(client === undefined) {
    client = new WheatleyMessagePreviewer(await getConfiguration());
  }
  return client;
};
