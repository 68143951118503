import 'ace-builds/css/ace.css';

import { useEffect, useState } from 'react';

export interface AceHook {
    ace: any;
    isLoading: boolean;
}

export function useAce(): AceHook {
  const [ ace, setAce ] = useState<typeof import('ace-builds')>();
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(function() {
    async function loadAce() {
      const ace = await import('ace-builds');
      await import('ace-builds/webpack-resolver');
      ace.config.set('useStrictCSP', true);
      ace.config.set('loadWorkerFromBlob', false);
      // ace.session.setMode("ace/mode/javascript");

      return ace;
    }

    loadAce()
      .then(ace => setAce(ace))
      .finally(() => setIsLoading(false));
  }, []);

  return {
    ace,
    isLoading,
  };
}
