import { SpaceBetween, Button } from '@amzn/awsui-components-react';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ActionsElement from './ActionsElement';

interface ActionsGroupElementProps {
  name: string;
}

function ActionsGroupElement({ name }: ActionsGroupElementProps): JSX.Element {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return <SpaceBetween direction="vertical" size="l">
    {fields.map((field, index) => (
      <ActionsElement key={index} name={`${name}.${index}`}></ActionsElement>
    ))}
    <Button onClick={() => append({ type: 'button', text: 'Click here', callbackId: '', value: '' })}>Add action</Button>
  </SpaceBetween>;
}

export default ActionsGroupElement;
